<script setup>
const handlerTo = (link) => {
    navigateTo(link);
};
</script>

<template>
    <div class="description-medium">
        <img class="description-medium__logo" src="@/assets/img/logo.svg" id="logo" alt="Air Arts" title="Логотип Air Arts" @click="handlerTo('/')" />
        <div class="description-medium__logo-text" @click="handlerTo('/')">
            Air Arts
        </div>
        <div class="description-medium__logo-description" @click="handlerTo('/')">
            for fresh air
        </div>
        <div class="description-medium__paragraph">
            Компания <span class="description-medium__paragraph-company">Air Arts </span>входит в число лучших
            специализированных
            организаций, с широким ассортиментом товаров и
            услуг, предназначенных для систем вентиляции и кондиционирования, предоставляющих высокий сервис и удобство
            обслуживания нашим клиентам.
        </div>
        <div class="description-medium__line"></div>
        <div class="description-medium__signature">Чистый воздух вместе с <span
                class="description-medium__signature-company">Air
                Arts</span></div>
    </div>
</template>

<style lang="less">
.description-medium {
    position: relative;
    width: 300px;
    margin-top: 50px;

    &__logo {
        width: 168px;
        height: 109px;
        margin-right: 58px;
        margin-bottom: 30px;
        cursor: pointer;

        &-text {
            font-family: 'Ruberoid';
            font-weight: 900;
            font-size: 4rem;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            position: absolute;
            left: 126px;
            top: 45px;
            cursor: pointer;
            color: #495e4c;
        }

        &-description {
            font-family: 'Ruberoid';
            font-weight: 500;
            font-size: 2.2rem;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            position: absolute;
            left: 126px;
            top: 80px;
            color: #78c081;
            cursor: pointer;
            color: var(--text2);
        }
    }

    &__paragraph {
        font-family: 'Proxima Nova Rg';
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(124, 163, 129, 1);
        margin-bottom: 25px;

        &-company {
            font-weight: 600;
        }
    }

    &__line {
        width: 100%;
        height: 1px;
        background-color: rgba(124, 163, 129, 1);
        margin-bottom: 35px;
    }

    &__signature {
        width: 250px;
        font-family: 'Ruberoid';
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 93, 76, 1);

        &-company {
            font-family: 'Ruberoid';
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(120, 192, 129, 1);
        }
    }
}
</style>
